import { updateValues } from "./services/state-actions";
import { PLATFORM } from "aurelia-pal";
import { Router, RouterConfiguration } from "aurelia-router";
//import 'bootstrap-css-only/css/bootstrap.min.css'
// import "@fortawesome/fontawesome-free/css/all.min.css"

import { autoinject } from "aurelia-framework";
import { Store } from "aurelia-store";
import { State } from "services/state";
import { AuthenticationService } from "services/authentication-service";

@autoinject()
export class App {
    router: Router;
    private _store: Store<State>;
    private _authenticationService: AuthenticationService;
    sidebarOpen: boolean = true;

    constructor(
        store: Store<State>,
        authenticationService: AuthenticationService
    ) {
        this._store = store;
        this._store.registerAction("updateValues", updateValues);
        this._authenticationService = authenticationService;
    }

    async configureRouter(config: RouterConfiguration, router: Router) {
        config.title = "LabLink";

        const clientData = await fetch(`/clients/getClient`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this._authenticationService.session.token}`,
            },
        });

        this._authenticationService.client = await clientData.json();

        // console.log(this._authenticationService.client);

        var routes = [
            {
                route: ["dashboard", ""],
                name: "dashboard",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: true,
                },
                moduleId: PLATFORM.moduleName("./pages/dashboard"),
                nav: true,
                title: "Dashboard",
                config: {},
            },
            {
                route: ["settings"],
                name: "settings",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: true,
                },
                moduleId: PLATFORM.moduleName("./pages/settings"),
                nav: true,
                title: "Settings",
                config: {},
            },
            {
                route: ["clinics"],
                name: "clinics",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName("./pages/clinics"),
                nav: true,
                title: "Clinics",
                config: {},
            },
            {
                route: ["veterinarians"],
                name: "veterinarians",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName("./pages/veterinarians"),
                nav: true,
                title: "Veterinarians",
                config: {},
            },
            {
                route: ["test-menu"],
                name: "test-menu",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName("./pages/test-menu"),
                nav: true,
                title: "Test Menu",
                config: {},
            },
            {
                route: ["diagnostics"],
                name: "diagnostics",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName("./pages/diagnostics"),
                nav: true,
                title: "Diagnostics",
                config: {},
            },
            {
                route: ["review-queue"],
                name: "review-queue",
                settings: {
                    icon: "list-check",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName("./pages/review-queue"),
                nav: true,
                title: "Review Queue",
                config: {},
            },
            {
                route: ["report-queue"],
                name: "report-queue",
                settings: {
                    icon: "list-check",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName("./pages/report-queue"),
                nav: true,
                title: "Report Queue",
                config: {},
            },
            {
                route: ["lab-requisition"],
                name: "lab-requisition",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName("./pages/lab-requisition"),
                nav: true,
                title: "Lab Requsition",
                config: {},
            },
            {
                route: ["create-lab-requisition"],
                name: "create-lab-requisition",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName("./pages/create-lab-requisition"),
                nav: true,
                title: "Create Lab Requsition",
                config: {},
            },
            {
                route: ["print-lab-requisition/:id"],
                name: "print-lab-requisition",
                settings: {
                    icon: "print",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName("./pages/print-lab-requisition"),
                nav: false,
                title: "Print Lab Requsition",
                config: {},
            },
            {
                route: ["lab-requisitions"],
                name: "lab-requisitions",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName("./pages/lab-requisitions"),
                nav: true,
                title: "Lab Requsitions",
                config: {},
            },
            {
                route: ["lab-result"],
                name: "lab-result",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName("./pages/lab-result"),
                nav: true,
                title: "Lab Result",
                config: {},
            },
            {
                route: ["courier-dashboard"],
                name: "courier-dashboard",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName("./pages/courier-dashboard"),
                nav: true,
                title: "Courier Dashboard",
                config: {},
            },
            {
                route: ["courier-status"],
                name: "courier-status",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName("./pages/courier-status"),
                nav: true,
                title: "Courier Status",
                config: {},
            },
            {
                route: ["print-courier-sheet"],
                name: "print-courier-sheet",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName("./pages/print-courier-sheet"),
                nav: false,
                title: "Print Courier Sheet",
                config: {},
            },
            {
                route: ["print-courier-sheet-all"],
                name: "print-courier-sheet-all",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName(
                    "./pages/print-courier-sheet-all"
                ),
                nav: false,
                title: "Print All Courier Sheet",
                config: {},
            },
            {
                route: ["supply-order-form"],
                name: "supply-order-form",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName(
                    "./pages/supplies/supply-order-form"
                ),
                nav: true,
                title: "Supply Order Form",
                config: {},
            },
            {
                route: ["admin-supply-orders"],
                name: "admin-supply-orders",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName(
                    "./pages/supplies/admin-supply-orders"
                ),
                nav: true,
                title: "Admin Supply Orders",
                config: {},
            },
            {
                route: ["patients"],
                name: "patients",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName("./pages/patients"),
                nav: true,
                title: "Patients",
                config: {},
            },
            {
                route: ["patient/:patientNumber"],
                name: "patient",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName("./pages/patient"),
                nav: true,
                title: "Patients",
                config: {},
                href: "",
            },
            {
                route: ["lab-results"],
                name: "lab-results",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName("./pages/lab-results"),
                nav: true,
                title: "Lab Results",
                config: {},
            },
            {
                route: ["lab-result/:accessionId"],
                name: "lab-result",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName("./pages/lab-result"),
                nav: true,
                title: "Lab Results",
                config: {},
                href: "",
            },
        ];

        if (
            this._authenticationService.client.userName ==
                "colleen.reed@truenorthvet.ca" ||
            this._authenticationService.client.userName == "scott"
        ) {
            routes.push({
                route: ["client-courier-settings"],
                name: "client-courier-settings",
                settings: {
                    icon: "tasks",
                    isExpanded: false,
                    subPages: [],
                    showInNavigation: false,
                },
                moduleId: PLATFORM.moduleName(
                    "./pages/client-courier-settings"
                ),
                nav: false,
                title: "Client Courier Settings",
                config: {},
            });
        }

        config.map(routes);

        this.router = router;
    }
}
