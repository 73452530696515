import { autoinject, TaskQueue, observable } from "aurelia-framework";
import { IAccession, IDashboardAccessions } from "interfaces/entity-interfaces";
import { DataService } from "services/data-service";
import Swal from "sweetalert2";
import * as JsBarcode from "jsbarcode";
import { Router } from "aurelia-router";

@autoinject
export class TestMenu {
    private _dataService: DataService;
    private _taskQueue: TaskQueue;

    allDiagnostics: any[] = [];
    diagnostics: any[] = [];
    _router: Router;
    topTests = [
        "216",
        "220",
        "SP",
        "SPU",
        "106",
        "702",
        "366",
        "525",
        "BP",
        "SAC",
    ];
    @observable({ changeHandler: "diagnosticSearchTermUpdated" })
    diagnosticSearchTerm: string = null;

    constructor(
        dataService: DataService,
        taskQueue: TaskQueue,
        router: Router
    ) {
        this._dataService = dataService;
        this._taskQueue = taskQueue;
        this._router = router;
    }

    async activate() {
        this._dataService
            .getDiagnostics({ hideHidden: true })
            .then((diagnostics) => {
                this.allDiagnostics = diagnostics;

                //take first ten items
                this.diagnostics = diagnostics.filter((d) =>
                    this.topTests.includes(d.code)
                );
            });
    }

    diagnosticSearchTermUpdated() {
        if (this.diagnosticSearchTerm == null) {
            return;
        }

        if (this.diagnosticSearchTerm == "") {
            this.diagnostics = this.allDiagnostics.filter((d) =>
                this.topTests.includes(d.code)
            );
            return;
        }

        const matchedDiagnosticsByCode = this.allDiagnostics.filter((d) =>
            d.code
                .toLowerCase()
                .includes(this.diagnosticSearchTerm.toLowerCase())
        );
        const matchedDiagnosticsByName = this.allDiagnostics.filter((d) =>
            d.name
                .toLowerCase()
                .includes(this.diagnosticSearchTerm.toLowerCase())
        );

        // console.log(matchedDiagnosticsByCode);
        // console.log(matchedDiagnosticsByName);

        this.diagnostics = [
            ...matchedDiagnosticsByCode,
            ...matchedDiagnosticsByName,
        ];
    }

    getFullPatientSex(short) {
        switch (short) {
            case "M":
                return "Male";
            case "F":
                return "Female";
            default:
                "";
        }
    }
    getFullPatientNeutered(short) {
        switch (short) {
            case "Y":
                return "Neutered";
            case "N":
                return "Not Neutered";
            default:
                "";
        }
    }
}
