import { HistoModal } from "modals/requisition-modals/histo-modal";
import { DialogService } from "aurelia-dialog";
import { DataService } from "services/data-service";
import { observable, autoinject, TaskQueue } from "aurelia-framework";
import { NewPatientModal } from "modals/new-patient-modal";
import {
    IBreed,
    IClient,
    IPatient,
    ISpecies,
    IVeterinarian,
} from "interfaces/entity-interfaces";
import { UrinalysisModal } from "modals/requisition-modals/urinalysis-modal";
import { AuthenticationService } from "services/authentication-service";
import * as moment from "moment";
import Swal from "sweetalert2";
import { Router } from "aurelia-router";
import { Dropzone } from "dropzone";

@autoinject
export class CreateLabRequisition {
    private _dataService: DataService;
    private _dialogService: DialogService;
    private _authenticationService: AuthenticationService;
    private _taskQueue: TaskQueue;
    router: Router;

    client: IClient;
    currentDateTime: Date;
    dayMonthYear: string;
    time: string;
    validationError: boolean = false;

    requisitionStep:
        | "Select Patient"
        | "Patient Details"
        | "Review Requisition" = "Select Patient";
    isEditing: boolean = true;

    topTests = [
        "216",
        "220",
        "SP",
        "SPU",
        "106",
        "702",
        "366",
        "525",
        "BP",
        "SAC",
    ];

    searchingPatients: boolean = false;

    @observable({ changeHandler: "searchUpdated" })
    searchTerm: string = null;

    @observable({ changeHandler: "diagnosticSearchTermUpdated" })
    diagnosticSearchTerm: string = null;

    speciesAndBreeds: any;
    species: ISpecies[] = [];
    breeds: IBreed[] = [];
    allDiagnostics: any[] = [];
    diagnostics: any[] = [];
    veterinarians: any[] = [];
    availableSexes = [
        { id: "FS", description: "Female - Spayed" },
        { id: "MN", description: "Male - Neutered" },
        { id: "F", description: "Female - Unspayed" },
        { id: "M", description: "Male - Unneutered" },
        { id: "FS", description: "Female - Unknown" },
        { id: "FS", description: "Male - Unknown" },
    ];
    ageYears: number;
    ageMonths: number;

    selectedDiagnostics: any[] = [];
    selectedPatient: IPatient;
    selectedVeterinarian: IVeterinarian;
    @observable selectedSpecies: ISpecies;
    selectedBreed: IBreed;

    patients: Array<IPatient> = [];

    isStaffPet: boolean = false;
    clinicToCC: string = "";
    externalRefNumber: string = "";

    areRepeatProfilesAvailable: boolean = false;
    previousAccessionID: string = "V230701100";

    patientSex: any;

    @observable requisitionNotes: string = "";

    sendingLabRequisition: boolean = false;
    accessionId: string;

    uploadingAttachment: boolean = false;
    attachmentDropzoneExists: boolean = false;
    requisitionAttachments: any[] = [];

    handlePrint: (e: Event) => void;

    constructor(
        dataService: DataService,
        dialogService: DialogService,
        authenticationService: AuthenticationService,
        router: Router,
        _taskQueue: TaskQueue
    ) {
        this._dataService = dataService;
        this._dialogService = dialogService;
        this._authenticationService = authenticationService;
        this.currentDateTime = moment().toDate();
        this.dayMonthYear = moment().format("MMM DD, YYYY");
        this.time = moment().format("h:mm A");
        this.router = router;
        this._taskQueue = _taskQueue;

        this.handlePrint = (e) => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        };
    }

    attached() {
        this.client = this._authenticationService.client;
        window.addEventListener("beforeprint", this.handlePrint);
    }

    async activate() {
        this._dataService
            .getVeterinariansDataFromServerAsync(null)
            .then((data) => {
                this.veterinarians = data;
            });
        const speciesAndBreeds =
            await this._dataService.getSpeciesAndBreedsDataFromServerAsync(
                null
            );

        this.speciesAndBreeds = speciesAndBreeds;

        const species = speciesAndBreeds.species.map((s) => {
            return {
                id: s.id,
                descr: this.formatDisplayText(s.descr),
                short_descr: s.short_descr,
            };
        });
        const breeds = speciesAndBreeds.breeds;

        this.species = species;
        this.breeds = breeds;

        this._dataService
            .getDiagnostics({ hideHidden: true })
            .then((diagnostics) => {
                this.allDiagnostics = diagnostics;

                //take first ten items
                this.diagnostics = diagnostics.filter((d) =>
                    this.topTests.includes(d.code)
                );
                console.log(this.diagnostics);
            });
    }

    selectedSpeciesChanged(newSpecies, oldSpecies) {
        if (newSpecies == null) return;

        var breeds = this.speciesAndBreeds.breeds.filter((b) => {
            return (
                b.species == newSpecies.id && b.descr != "" && b.descr != null
            );
        });

        this.breeds = breeds.map((breed) => {
            return {
                species: breed.species,
                breed: breed.breed,
                descr: this.formatDisplayText(breed.descr),
            };
        });

        this.checkSpeciesandDiagnostics();
    }

    async searchUpdated() {
        if (this.searchTerm == null) return;

        this.searchingPatients = true;
        this.patients = await this._dataService.getPatientsDataFromServerAsync({
            searchTerm: this.searchTerm,
        });
        // console.log(this.patients);
        this.searchingPatients = false;
    }
    diagnosticSearchTermUpdated() {
        if (this.diagnosticSearchTerm == null) {
            return;
        }

        if (this.diagnosticSearchTerm == "") {
            this.diagnostics = this.allDiagnostics.filter((d) =>
                this.topTests.includes(d.code)
            );
            return;
        }

        const matchedDiagnosticsByCode = this.allDiagnostics.filter((d) =>
            d.code
                .toLowerCase()
                .includes(this.diagnosticSearchTerm.toLowerCase())
        );
        const matchedDiagnosticsByName = this.allDiagnostics.filter((d) =>
            d.name
                .toLowerCase()
                .includes(this.diagnosticSearchTerm.toLowerCase())
        );

        // console.log(matchedDiagnosticsByCode);
        // console.log(matchedDiagnosticsByName);

        this.diagnostics = [
            ...matchedDiagnosticsByCode,
            ...matchedDiagnosticsByName,
        ];
    }

    displayPatientSpecies(patientSpecies) {
        return this.species.find((s) => s.id == patientSpecies).descr;
    }

    displayPatientBreed(patientSpecies, patientBreed) {
        // console.log(patientSpecies, patientBreed);
        // console.log(this.breeds);
        const breed = this.breeds.find(
            (s) => s.species == patientSpecies && s.breed == patientBreed
        );

        if (breed == null) return "";

        return breed.descr;
    }

    openNewPatientModal() {
        console.log(this._dialogService);

        this._dialogService
            .open({
                viewModel: NewPatientModal,
                model: { speciesAndBreeds: this.speciesAndBreeds },
                lock: false,
            })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    console.log(response);
                    this.selectPatient(response.output);
                } else {
                }
                console.log(response.output);
            });
    }

    selectPatient(patient) {
        this.selectedPatient = patient;

        // var returnedData = await fetch("", {
        //     headers: {
        //         "Content-Type": "application/json",
        //         Accept: "application/json",
        //         Authorization: `Bearer ${this._authenticationService.session.token}`,
        //     },
        // });

        // const jsonUnpackedData = await returnedData.json();

        // this.areRepeatProfilesAvailable = jsonUnpackedData.areRepeatProfilesAvailable;
        // this.previousAccessionID = jsonUnpackedData.previousAccessionID;

        this.speciesAndBreeds.species.find((s) => s.id == patient.species);
        this.selectedBreed = this.speciesAndBreeds.breeds.find(
            (s) => s.species == patient.species && s.breed == patient.breed
        );

        const patientSex = `${this.getPatientSex(
            patient
        )} - ${this.getPatientNeutered(patient)}`;

        this.patientSex = this.availableSexes.find((as) => {
            if (as.description == patientSex) return as;
        });

        this.closeDropdown();
        this.requisitionStep = "Patient Details";
    }

    closeDropdown() {
        this.searchTerm = null;
    }

    toggleExtraDetails(diagnostic) {
        diagnostic.areExtraDetailsOpen = !diagnostic.areExtraDetailsOpen;
    }

    toggleDiagnostic(diagnostic) {
        if (this.requisitionStep == "Review Requisition") return;

        if (
            !diagnostic.isSelected &&
            (diagnostic.code == "220" ||
                diagnostic.code == "360" ||
                diagnostic.code == "366" ||
                diagnostic.code == "366a" ||
                diagnostic.code == "371" ||
                diagnostic.code == "377" ||
                diagnostic.code == "BPTU" ||
                diagnostic.code == "BPU" ||
                diagnostic.code == "CPTU" ||
                diagnostic.code == "CPU" ||
                diagnostic.code == "FPTU" ||
                diagnostic.code == "FPTUF" ||
                diagnostic.code == "FPU" ||
                diagnostic.code == "FPUF" ||
                diagnostic.code == "RBPTU" ||
                diagnostic.code == "RBPU" ||
                diagnostic.code == "RCPU" ||
                diagnostic.code == "RPU" ||
                diagnostic.code == "RSACU" ||
                diagnostic.code == "RSPU" ||
                diagnostic.code == "RSPUF" ||
                diagnostic.code == "SACTU" ||
                diagnostic.code == "SACU" ||
                diagnostic.code == "SPU" ||
                diagnostic.code == "SPUF")
        ) {
            this.openUrinalysisModal(diagnostic);
            return;
        }
        if (
            !diagnostic.isSelected &&
            (diagnostic.code == "700" ||
                diagnostic.code == "701" ||
                diagnostic.code == "702" ||
                diagnostic.code == "703" ||
                diagnostic.code == "704" ||
                diagnostic.code == "705" ||
                diagnostic.code == "106" ||
                diagnostic.code == "107" ||
                diagnostic.code == "108" ||
                diagnostic.code == "117" ||
                diagnostic.code == "119" ||
                diagnostic.code == "124" ||
                diagnostic.code == "136")
        ) {
            this.openHistoModal(diagnostic);
            return;
        }

        diagnostic.isSelected = !diagnostic.isSelected;

        if (diagnostic.isSelected) this.selectedDiagnostics.push(diagnostic);
        else
            this.selectedDiagnostics.splice(
                this.selectedDiagnostics.indexOf(diagnostic),
                1
            );

        this.checkSpeciesandDiagnostics();
    }

    checkSpeciesandDiagnostics() {
        if (this.selectedSpecies == null) return;
        if (this.selectedDiagnostics.length == 0) return;

        const selectedSpecies = this.selectedSpecies.descr;
        const speciesToCheckCopy = [...this.species];

        const speciesToCheck = ["canine", "feline", "equine"];
        const diagnosticsToCheck = this.selectedDiagnostics;

        const speciesMentioned = [];
        const diagnosticsMentioned = [];

        //I need to check all of the options in speciesToCheck to see if any species appears in the description or title.
        speciesToCheck.forEach((species) => {
            diagnosticsToCheck.forEach((diagnostic) => {
                const description = diagnostic.description
                    ? diagnostic.description.toLowerCase()
                    : "";
                const title = diagnostic.name.toLowerCase();

                if (description.includes(species) || title.includes(species)) {
                    speciesMentioned.push(species);
                    diagnosticsMentioned.push(diagnostic.name);
                }
            });
        });

        //if speciesMentioned includes a species other than the selectedSpecies, alert:
        if (speciesMentioned.length > 0) {
            if (
                !speciesMentioned.includes(selectedSpecies.toLowerCase()) ||
                speciesMentioned.length > 1
            ) {
                Swal.fire({
                    icon: "info",
                    title: "Check Species",
                    text: `There is a test selected that is not meant for ${selectedSpecies} patients. The test(s) are: ${diagnosticsMentioned.join(
                        ", "
                    )}`,
                    confirmButtonText: "Okay",
                    confirmButtonColor: "#28a745",
                }).then((result) => {
                    if (result.isConfirmed) {
                    } else {
                    }
                });
                return;
            }
        }
    }

    openHistoModal(diagnostic) {
        this._dialogService
            .open({
                viewModel: HistoModal,
                model: diagnostic,
                lock: true,
            })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                } else {
                    if (!diagnostic.isSelected) {
                        diagnostic.isSelected = !diagnostic.isSelected;
                        this.selectedDiagnostics.push(diagnostic);
                    }
                }
                console.log("Histo Diagnostic", diagnostic);
            });
    }

    openUrinalysisModal(diagnostic) {
        this._dialogService
            .open({
                viewModel: UrinalysisModal,
                model: diagnostic,
                lock: true,
            })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                } else {
                    if (!diagnostic.isSelected) {
                        diagnostic.isSelected = !diagnostic.isSelected;
                        this.selectedDiagnostics.push(diagnostic);
                    }
                }
            });
    }

    getPatientSex(patient) {
        switch (patient.sex) {
            case "M":
                return "Male";
            case "F":
                return "Female";
            default:
                break;
        }
    }
    getPatientNeutered(patient) {
        if (patient.sex == "M") {
            if (patient.neutered == "N") {
                return "Unneutered";
            } else if (patient.neutered == "Y") {
                return "Neutered";
            } else {
                return "Unknown";
            }
        }
        if (patient.sex == "N") {
            if (patient.neutered == "N") {
                return "Unspayed";
            } else if (patient.neutered == "Y") {
                return "Spayed";
            } else {
                return "Unknown";
            }
        }
    }

    toggleEditing() {
        this.isEditing = !this.isEditing;
    }
    toggleStaffPet() {
        this.isStaffPet = !this.isStaffPet;
    }

    get arePatientDetailsValidated(): boolean {
        return (
            this.selectedPatient != null &&
            this.selectedVeterinarian != null &&
            this.selectedSpecies != null &&
            this.selectedBreed != null
        );
    }

    requisitionNotesChanged(newValue, oldValue) {
        if (newValue.length >= 255) return;
        this.requisitionNotes = newValue;
    }

    async reviewRequisition() {
        if (
            !this.patientSex ||
            (!this.ageMonths && !this.ageYears) ||
            !this.selectedVeterinarian ||
            this.selectedDiagnostics.length == 0
        ) {
            this.validationError = true;
            await setTimeout(() => {
                this.validationError = false;
            }, 3000);
            return;
        }
        this.requisitionStep = "Review Requisition";
        this._taskQueue.queueMicroTask(() => {
            this.loadDropzone();
        });
    }

    restartRequisition() {
        this.requisitionStep = "Select Patient";
        this.selectedDiagnostics = [];
        this.selectedVeterinarian = null;
        this.diagnostics.forEach((d) => {
            d.isSelected = false;
        });
    }

    returnToDashboard() {
        this.router.navigateToRoute("clinics");
        this.restartRequisition();
    }

    ////////Utility Functions////////
    formatDisplayText(wordToDisplay: string) {
        const words = wordToDisplay.split(" ");

        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].toLowerCase();
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }

        return words.join(" ");
    }

    printRequisition() {
        window.print();
    }

    async sendRequisition() {
        let massBiospyData: IMassBiopsyDataDTO;

        this.selectedDiagnostics.forEach((d) => {
            if (d.histo != null) {
                massBiospyData = {
                    massLocation: d.histo.mass.location,
                    massSize: d.histo.mass.size,
                    massConsistency: d.histo.mass.consistency,
                    massAttachment: d.histo.mass.attachment,
                    massAge: d.histo.mass.ageOfMass,
                    primaryLegions: d.histo.biopsy.primaryLegions,
                    secondaryLegions: d.histo.biopsy.secondaryLegions,
                    isPruritus: d.histo.biopsy.pruritus,
                    pruritusText: d.histo.biopsy.pruritusDescription,
                    isParasites: d.histo.biopsy.parasites,
                    parasitesText: d.histo.biopsy.parasitesDescription,
                };
            }
        });

        let labRequisition: ICreateLabRequisition = {
            generalLabRequisitionData: {
                animalName: this.selectedPatient.patientFirstName,
                ownerLastName: this.selectedPatient.patientLastName,
                ownerFirstName: this.selectedPatient.ownerFirstName,
                hospitalId: this.externalRefNumber,
                species: this.selectedSpecies.id,
                breed: this.selectedBreed.breed,
                ageYears: this.ageYears.toString(),
                ageMonths: this.ageMonths.toString(),
                sex: this.patientSex.id,
                patientNumber: this.selectedPatient.patientNumber,
                repeatAccessionId: "",
                veterinarian: {
                    name: this.selectedVeterinarian.name,
                    id: this.selectedVeterinarian.id,
                },
            },
            selectedClassifications: [],
            history: this.requisitionNotes,
            medications: "",
            urineCollectionMethod: this.selectedDiagnostics
                .filter((d) => {
                    return d.sampleCollectionType != null;
                })
                .map((d) => {
                    return d.sampleCollectionType;
                })
                .join(),
            massBiopsyData: massBiospyData,
            clientToCCEmail: this.clinicToCC,
            selectedTests: this.selectedDiagnostics.map((d) => {
                return {
                    unitCode: d.code,
                    name: d.name,
                    profile: d.isProfile,
                    selectedOptions: d.selectedOptions,
                };
            }),
            attachments: this.requisitionAttachments,
        };

        Swal.fire({
            icon: "info",
            title: "Sending Lab requisition",
            text: "",
            showDenyButton: false,
            showConfirmButton: false,
        });
        var result = await this._dataService
            .createLabRequisition(labRequisition)
            .then((data) => {
                // console.log(data);
                Swal.fire({
                    icon: "success",
                    title: "Successfully Created Lab Requisition!",
                    text: "Please print this receipt and attach to your physical samples.",
                    showConfirmButton: true,
                }).then(() => {
                    this.router.navigateToRoute("print-lab-requisition", {
                        id: data.accessionId,
                    });
                });
            });
    }

    loadDropzone() {
        if (!this.attachmentDropzoneExists) {
            const attachmentDropzoneId = `#attachment-dropzone`;
            let attachmentDropzone = new Dropzone(attachmentDropzoneId, {
                url: "/attachments",
                // paramName: "file",
                success: async (file, attachmentUrl) => {
                    // console.log("file", file);
                    this.uploadingAttachment = false;
                    this.requisitionAttachments.push(attachmentUrl);
                    this.attachmentDropzoneExists = true;
                    console.log("DZ File", file);
                    //Select the preview element
                    var previewImg = file.previewElement.querySelector("img");
                    if (file.type == "application/pdf") {
                        previewImg.src = "https://i.imgur.com/ai9HYiE.png";
                    }
                },
                error: (file, response) => {
                    // console.log("file", file);
                    // console.log("response", response);
                },

                headers: {
                    Authorization:
                        "Bearer " + this._authenticationService.session.token,
                },
                clickable: `#clickable-attachment-dropzone`,
                previewsContainer: ".dropzone-previews",
                uploadMultiple: true,
                // parallelUploads: 100,
                // maxFiles: 100,
            });
            attachmentDropzone.on("uploadprogress", (file, progress) => {
                console.log("File", file);
            });
            attachmentDropzone.on("addedfile", (file) => {
                console.log("Initialize upload client -> server: ");
                console.log(file);
            });
            this.attachmentDropzoneExists = true;
        }
    }
}

interface ICreateLabRequisition {
    generalLabRequisitionData: {
        animalName: string;
        ownerLastName: string;
        ownerFirstName: string;
        hospitalId: string;
        species: string;
        breed: string;
        ageYears: string;
        ageMonths: string;
        sex: string;
        patientNumber: string;
        repeatAccessionId: string;
        veterinarian: IVeterinarian;
    };
    selectedClassifications: Array<string>;
    history: string;
    medications: string;
    urineCollectionMethod: string;
    massBiopsyData: IMassBiopsyDataDTO;
    clientToCCEmail: string;
    selectedTests;
    attachments: string[];
}

interface IMassBiopsyDataDTO {
    massLocation: string;
    massSize: string;
    massConsistency: string;
    massAttachment: string;
    massAge: string;
    primaryLegions: string[];
    secondaryLegions: string[];
    isPruritus: string;
    pruritusText: string;
    isParasites: string;
    parasitesText: string;
}

export interface ISelectedTestDTO {
    name: string;
    profile: boolean;
    unitCode: string;
    selectedOptions: ISelectedOption[];
}

export interface ISelectedOption {
    name: string;
    code: string;
    order: number;
}
