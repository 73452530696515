import { DataService } from "services/data-service";
import { autoinject } from "aurelia-framework";
import Swal from "sweetalert2";


@autoinject()
export class Veterinarians {
	private _dataService: DataService;

	isLoadingVeterinarians: boolean = false;

	veterinarians: any[] = [];

	constructor(
		dataService: DataService,
	) {
		this._dataService = dataService;
	}

	async activate(params, routeConfig, navigationInstruction) {
		await this.getVeterinarians();
	}

	deleteVeterinarian(veterinarian) {

		Swal.fire({
			icon: "warning",
			title: "Are you sure?",
			html: `<p>This will delete the veterinarian.</p><p>Name: ${veterinarian.firstName} ${veterinarian.lastName}</p>`,
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Ok",
		}).then(async (result) => {
			if (result.value) {
				await this._dataService.deleteVeterinarian(veterinarian);
				await this.getVeterinarians();
			}
		});
	}

	addVeterinarian() {
		console.log("Add Veterinarian");

		Swal.fire({
			title: "Enter Veterinarian Name",
			input: "text",
			showCancelButton: true,
			confirmButtonText: "Add",
			preConfirm: (name) => {
				console.log("Name", name);

				if (name == null || name == "") {
					Swal.showValidationMessage("Name is required");
				}

				return name;
			},
		}).then(async (result) => {
			if (result.value) {
				await this._dataService.createVeterinarian(result.value);
				await this.getVeterinarians();

			}
		});
	}

	editVeterinarian(veterinarian) {
		Swal.fire({
			title: "Enter Veterinarian Name",
			input: "text",
			inputValue: `${veterinarian.firstName} ${veterinarian.lastName}`,
			showCancelButton: true,
			confirmButtonText: "Update",
			preConfirm: (name) => {
				console.log("Name", name);

				if (name == null || name == "") {
					Swal.showValidationMessage("Name is required");
				}

				return name;
			},
		}).then(async (result) => {
			if (result.value) {
				console.log("Update Veterinarian", result.value);
				// ve
				// await this._dataService.updateVeterinarian(veterinarian, result.value);
				// await this.getVeterinarians();
			}
		});
	}

	async getVeterinarians() {
		this.isLoadingVeterinarians = true;

		const dbVeterinarians = await this._dataService.getVeterinariansDataFromServerAsync(
			null
		);


		this.veterinarians = dbVeterinarians.map((v) => {
			const isDVM = v.name.includes("DVM") || v.name.includes("Dr");

			const name = v.name.replace("DVM", "").replace("Dr.", "").replace("Dr", "").trim();

			return {
				firstName: name.split(" ")[0],
				lastName: name.split(" ")[1],
				isDVM: isDVM,
			};
		});

		console.log("Veterinarians", this.veterinarians);

		this.isLoadingVeterinarians = false;
	}


}